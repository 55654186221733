"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.slider = void 0;
var keen_slider_1 = require("keen-slider");
require("keen-slider/keen-slider.min.css");
var slider = function () {
    var sliderElem = document.getElementById('my-keen-slider');
    var interval = 0;
    function autoplay(run) {
        clearInterval(interval);
        interval = window.setInterval(function () {
            if (run && myKeenSlider) {
                myKeenSlider.next();
            }
        }, 2000);
    }
    var myKeenSlider = new keen_slider_1.default(sliderElem, {
        loop: true,
        duration: 1000
        // dragStart: () => {
        //     autoplay(false)
        // },
        // dragEnd: () => {
        //     autoplay(true)
        // }
    });
    // sliderElem.addEventListener('mouseover', () => {
    //     autoplay(false)
    // })
    // sliderElem.addEventListener('mouseout', () => {
    //     autoplay(true)
    // })
    // autoplay(true)
};
exports.slider = slider;

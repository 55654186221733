"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var slider_1 = require("./ts/slider");
var script_1 = require("./ts/script");
require("sanitize.css");
require("./scss/style.scss");
setTimeout(function () {
    if (document.getElementsByTagName('html')[0].classList.contains('wf-active') != true) {
        document.getElementsByTagName('html')[0].classList.add('loading-delay');
    }
}, 3000);
(0, slider_1.slider)();
(0, script_1.script)();

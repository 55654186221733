"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.script = void 0;
var script = function () {
    var Header = document.getElementById('header');
    var CLASS_WHITE = 'bg-white';
    window.onscroll = function (e) {
        if (Header.clientHeight < document.scrollingElement.scrollTop) {
            Header.classList.add(CLASS_WHITE);
        }
        else {
            Header.classList.remove(CLASS_WHITE);
        }
    };
    var Burger = document.getElementById('burger');
    var HeaderNav = document.getElementById('headerNav');
    var HtmlElement = document.querySelector('html');
    var CLASS_ACTIVE = 'active';
    var CLASS_PREVENT = 'scroll-prevent';
    Burger.onclick = function (e) {
        Burger.classList.toggle(CLASS_ACTIVE);
        if (Burger.classList.contains(CLASS_ACTIVE)) {
            HeaderNav.classList.add(CLASS_ACTIVE);
            HtmlElement.classList.add(CLASS_PREVENT);
        }
        else {
            HeaderNav.classList.remove(CLASS_ACTIVE);
            HtmlElement.classList.remove(CLASS_PREVENT);
        }
    };
    window.addEventListener('DOMContentLoaded', function () {
        var AnchorLinks = document.querySelectorAll('a[href^="#"]');
        var AnchorLinksArr = Array.prototype.slice.call(AnchorLinks);
        AnchorLinksArr.forEach(function (anchorLink) {
            anchorLink.onclick = function (e) {
                if (Burger.classList.contains(CLASS_ACTIVE)) {
                    Burger.classList.remove(CLASS_ACTIVE);
                    HeaderNav.classList.remove(CLASS_ACTIVE);
                    HtmlElement.classList.remove(CLASS_PREVENT);
                }
                e.preventDefault();
                var targetId = anchorLink.hash;
                var targetElement = document.querySelector(targetId);
                var targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
                window.scrollTo({
                    top: targetOffsetTop - 200,
                    behavior: 'smooth'
                });
            };
        });
    });
};
exports.script = script;
